<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-12">
          <img src="https://postaje.vreme-podnebje.si/latest_web/latest.php" class="img img-fluid" alt="Responsive image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Webcam"
}
</script>
